<template>
    <div>
       
        <v-row>
            <v-col class="py-2" cols="12" md="6">
                <textFieldForm
                    label="Cliente"
                    
                    :valueInput="values.client"
                    :rule="[]"
                    :errorMessages="serverErrors.client_id"
                    data-cy="client-input"
                    :disabled="true"
                ></textFieldForm>
            </v-col>
            <v-col class="py-2" cols="12" md="6">
                <textFieldForm
                    label="Obra"
                    
                    :valueInput="values.work"
                    :rule="[]"
                    :errorMessages="serverErrors.work"
                    data-cy="work-input"
                    :disabled="true"
                ></textFieldForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2" cols="12" md="6">
                <textFieldForm
                    @valueChange="(v) => {values.discount = v}" 
                    :valueInput="values.discount" 
                    :rule="rules.cost" 
                    label="Descuento (%)"
                    :errorMessages="serverErrors.discount"
                    :prefix="(values.discount == '' || values.discount == null) ? '' : '%'"
                    data-cy="discount-input"
                ></textFieldForm>
            </v-col>
            <v-col class="py-2" cols="12" md="6">
                <datePickerForm
                    label="Fecha"
                    :valueInput="values.date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                    :errorMessages="serverErrors['date']"
                    data-cy="date-input"
                ></datePickerForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2" cols="12" md="6">
                <fileInputForm
                    
                    label="Factura PDF"
                    :multiple="false"
                    :valueInput="values.pdf_file"
                    :url="values.pdf_url"
                    :rule="[]"
                    @valueChange="(v) => {values.pdf_file = v; delete serverErrors.pdf_file}"
                    :errorMessages="serverErrors.pdf_file"
                    v-on:update:url="values.pdf_url = $event"
                    :disableDelete="false"
                    data-cy="pdf_file-input"
                ></fileInputForm>
            </v-col>
            <v-col class="py-2" cols="12" md="6">
                <fileInputForm
                    
                    label="Factura XML"
                    :multiple="false"
                    :valueInput="values.xml_file"
                    :url="values.xml_url"
                    :rule="[]"
                    @valueChange="(v) => {values.xml_file = v; delete serverErrors.xml_file}"
                    :errorMessages="serverErrors.xml_file"
                    v-on:update:url="values.xml_url = $event"
                    :disableDelete="false"
                    data-cy="xml_file-input"
                ></fileInputForm>
            </v-col>
        </v-row>




      

       


        

       

        <!-- table -->
        <v-row>
            <v-col class="py-2">
                <table-items data-cy="table-items" :props="tableProps" @methodHandler="method_handler" :items="values.products">
                    
                    <template v-slot:item.quantity="props" >

                        <div class="table-item">
                            <textFieldForm 
                                @valueChange="(v) => {props.item.quantity = v; delete serverErrors.quotation_details}" 
                                :valueInput="props.item.quantity" 
                                :rule="rules.cost" 
                                :disabled="props.item.disabled"
                                data-cy="table-quantity-input"
                                
                            ></textFieldForm>
                        </div>
                        
                    </template>

                    <template v-slot:item.price="props" >

                        <div class="table-item">
                            <textFieldForm 
                                @valueChange="(v) => {props.item.amount = v; delete serverErrors.quotation_details}" 
                                :valueInput="props.item.amount" 
                                :rule="[...rules.cost]" 
                                :disabled="props.item.disabled"
                                data-cy="table-amount-input"
                                
                            ></textFieldForm>
                        </div>
                        
                    </template>

                    <template v-slot:item.subtotal="props" >

                        <div class="table-item">
                          <span data-cy="table-subtotal-input">{{parseFloat(props.item.amount*props.item.quantity).toFixed(2)}}</span>
                        </div>
                        
                    </template>
                    
                </table-items>
                
            </v-col>
            
        </v-row>
        <span class="red--text caption" v-if="serverErrors[`saleOrder_details`]">{{serverErrors[`saleOrder_details`]}}</span>
      
      

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
           
            actions: [
				
				{
					name: 'Borrar',
					icon: {
						color: 'primary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItem',
                    dataCy: "delete-link"
				},
			],
            tableProps: {
				headers: [
                     {
						text: 'Producto',
						align: 'left break-words',
						value: 'name',
                        
						class: 'table-b-border black--text',
                        sortable: false,
					},
					{
						text: 'Cantidad',
						align: 'center',
						value: 'quantity',
                        width: "20%",
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Costo',
						align: 'center',
						value: 'price',
                        width: "20%",
						sortable: false,
						class: 'table-b-border black--text'
                    },
                    {
						text: 'Subtotal',
						align: 'center',
						value: 'subtotal',
                        width: "20%",
						sortable: false,
						class: 'table-b-border black--text'
					},
                   
					{
						text: '',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false
			},
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
               
                cost: [
                    v => {
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        return true;
                    }
                ],
                quantity: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
           
				
				
				
            },

        

          
        }
    },
   
    mounted(){
        this.loadProducts();
    },
    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        clear()
        {
            this.values.products = [];
        },
     

      

        loadProducts()
        {
            this.values.products = this.values.products.map((item,i) => {
                // x['parameters'] = { id: x.slug, name: x.sku, index: i};
               
                let newProd = {
                    actions: JSON.parse(JSON.stringify(this.actions)),
                    parameters: { id: item.product_id, name: item.product_name, index: 99},
                    id: item.product_id,
                    name: item.product_name,
                    quantity : item.quantity,
                    amount: item.amount,
                }
                return newProd;
            })
        },

        
        deleteItem(item)
        {
            let index = this.values.products.findIndex(prod => prod.id == item.id);

            console.log(index, item);
            this.values.products.splice(index, 1);
        },

       

    }
}
</script>

<style>

</style>